<template>
  <div>
    <v-snackbar v-model="snackbar.isActive"
                :color="snackbar.color"
                top
                right>
      {{ snackbar.message }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          icon
          v-bind="attrs"
          @click.native="$store.dispatch('notification/setSnackbar', { isActive: false, message: '', color:'' })">
          <v-icon small>mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Snackbar',
  computed: {
    ...mapGetters({
      snackbar: 'notification/snackbar'
    })
  }
};
</script>
